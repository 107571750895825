<template>
  <div class="map-wrapper">
    <yandex-map
      :coords="coordsItems[0]"
      :settings="settings"
      :show-all-markers="coordsItems.length > 1"
      :controls="[]"
      :zoom="zoom"
      @click="onClick"
    >
      <!-- <ymap-marker
        v-for="(coords, index) in coordsItems"
        :key="index"
        :coords="coords"
        :icon="markerIcon"
        :marker-id="index"
        :balloon-template="balloonTemplate(index)"
        :settings="{ title: coords }"
      >
        <div
          class="icon"
          :style="{
            '--size': 'size' in point ? point.size : '20px',
            '--color': 'color' in point ? point.color : undefined,
            '--icon': 'icon' in point ? `url(${point.icon})` : undefined,
          }"
        >
          <div v-if="'title' in point" class="icon_title" v-html="coords" />
        </div>
      </ymap-marker> -->
      <yandex-map-marker
        v-for="(coords, index) in coordsItems"
        :key="index"
        :settings="coords"
      >
        <div class="marker" />
      </yandex-map-marker>
    </yandex-map>

    <div
      class="close-map max-md:tw-top-5 max-md:tw-right-5 max-md:tw-left-auto"
      @click="$emit('close-map')"
    >
      <XIcon size="20" style="color: #ffffff;" />
    </div>
  </div>
</template>

<script>
import { yandexMap, ymapMarker } from "vue-yandex-maps";
import { XIcon } from "vue-feather-icons";
import dayjs from "dayjs";

export default {
  components: {
    yandexMap,
    ymapMarker,
    XIcon,
  },
  props: {
    coordsItems: Array,
    center: Array,
    elements: Array,
    zoom: Number,
  },
  data() {
    return {
      settings: {
        apiKey: "ad3a41b6-13be-4101-b96d-55ba0cab372d", // Индивидуальный ключ API
        lang: "ru_RU", // Используемый язык
        coordorder: "latlong", // Порядок задания географических координат
        debug: false, // Режим отладки
        version: "2.1", // Версия Я.Карт
      },
      markerIcon: {
        layout: "default#imageWithContent",
        imageHref: require("@/assets/icons/Тут разработать UX/Vector.png"),
        imageSize: [25, 37.5],
        imageOffset: [-12.5, -37.5],
      },
    };
  },
  computed: {
    coords() {
      return this.coordsItems.map((item) => item.coords);
    },
  },
  methods: {
    balloonTemplate(i) {
      return `
      <div class="zem-collapse-table rows">
        <div class="zem-collapse-table__row" style="grid-template-columns: 2fr 3fr; gap: 5px 10px;">
          <div class="zem-collapse-table__column title mt-1">Номер заказа</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.elements[i].order.data.title}
          </div>
          <div class="zem-collapse-table__column title mt-1">Тип мероприятия</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.elements[i].type.data.name}
          </div>
          <div class="zem-collapse-table__column title mt-1">Описание</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.elements[i].description}
          </div>
          <div class="zem-collapse-table__column title mt-1">Время начала</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${dayjs(this.elements[i].start_at * 1000).format(
              "DD.MM.YYYY HH:mm"
            )}
          </div>
          <div class="zem-collapse-table__column title mt-1">Статус</div>
          <div v-if="!edit && !newElement" class="zem-collapse-table__column text mt-1">
            ${this.elements[i].status.data.name}
          </div>
        </div>
      </div>
      `;
    },
    onClick(e) {
      // this.coords = e.get('coords');
    },
  },
};
</script>

<style lang="scss">
.ymap-container,
.map-wrapper {
  position: relative;
  width: 100%;
  height: 100%;

  .ymaps-2-1-79-balloon {
    border-radius: 6px;

    .ymaps-2-1-79-balloon__layout {
      border-radius: 6px;

      .ymaps-2-1-79-balloon__close {
        margin-right: 5px;
      }
    }
  }
}

.close-map {
  position: absolute;
  background: rgb(13, 178, 178);
  top: calc(50% - 16px);
  width: 32px;
  height: 32px;
  left: -16px;
  border-radius: 50%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 2px 4px 8px rgb(19 19 19 / 31%);
  }
}
</style>
